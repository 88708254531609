import Link from 'gatsby-link'
import React, { Component } from "react"
import { withTranslation } from "react-i18next"

import "./styles.scss"

class LanguageSwitcher extends Component {
    constructor(props) {
        super(props)
        this.lang = props.lang
        this.t = props.t
    }

    render() {

        return (
            <div className="language-switcher">
                <div className="active-language">
                    <span className="material-icons">language</span>
                    <span className="label">{this.t("languages.activeLanguage")}</span>
                    <span className="material-icons label">expand_more</span>
                </div>
                <ul className="available-languages">
                    <li className={`${this.lang === 'de' ? "active" : ""}`}>
                        <Link to="/de">
                            <span className="label">{this.t("languages.de")}</span>
                            {this.lang === 'de' ? <span className="material-icons">check</span> : ''}
                        </Link>   
                    </li>
                    <li className={`${this.lang === 'en' ? "active" : ""}`}>
                        <Link to="/en">
                            <span className="label">{this.t("languages.en")}</span>
                            {this.lang === 'en' ? <span className="material-icons">check</span> : ''}
                        </Link>
                    </li>
                </ul>
            </div>
        )
    }
}

export default withTranslation()(LanguageSwitcher)