import Link from "gatsby-link"
import React, { Component } from "react"

import "./styles.scss"

class Button extends Component {
    constructor(props) {
        super(props)
        this.type = props.type
        this.action = props.action
        this.onClick = props.action || false
        this.hasIcon = false

        this.to = props.to
        
        if (!this.to.startsWith('https://') && !this.to.startsWith('http://') && !this.to.startsWith('/') && !this.to.startsWith('tel:')  && !this.to.startsWith('mailto')) {
            this.to = `/${ this.to }`
        }

        if (typeof props.icon !== 'undefined') {
            this.hasIcon = true
            this.icon = props.icon
        }
    }

    render() {
        return (
            <div className="button-wrapper">
                {this.onClick
                    ? <span className={`button ${ this.type }`} onClick={this.onClick} onKeyDown={this.onClick} title={this.props.title} role="button" tabIndex={0}>{this.hasIcon && <span className="material-icons">{this.icon}</span>}{this.props.label}</span>
                    : <Link className={`button ${ this.type }`} to={this.to} title={this.props.title}>{this.props.label}</Link>
                }
            </div>
        )
    }
}  
  
export default Button