import React, { Component } from "react"
import { withTranslation } from "react-i18next"

import "./styles.scss"

class TopStory extends Component {
    constructor(props) {
        super(props)
        this.settings = props.settings
        this.t = props.t
    }

    render() {
        return (
            <div className="top-story">
                <a href={`/${this.settings.content.top_story.full_slug}`}>
                    <span className="label" title={this.t("topStory.title")}>{this.t("topStory.pre")}: {this.settings.content.top_story.name}</span>
                    <span className="material-icons">keyboard_arrow_right</span>
                    </a>
            </div>
        )
    }
}

export default withTranslation()(TopStory)