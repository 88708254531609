import React, { Component } from "react"
import Link from "gatsby-link"
import moment from "moment-timezone"
import SbEditable from "storyblok-react"

import "./styles.scss"

import { withTranslation } from "react-i18next"

class Footer extends Component {
    constructor(props) {
        super(props)
        this.t = props.t
        this.settings = props.settings
    }

    render() {
        const today = new moment().tz('Europe/Berlin')

        return (
            <footer className="footer">
                <div className="container">
                    <div className="footer-navigation">
                        <div className="column-1">
                            <h4>{this.settings.footer_navi_title_1}</h4>
                            <nav className="footer-menu" role="navigation" aria-label={ this.t("footer.navigation.menu1.label") }>
                                <ul>
                                {this.settings && this.settings.content.footer_navi_1.map((navitem, index) => (
                                    <SbEditable content={navitem} key={navitem._uid}>
                                        <li key={index}>
                                            <Link to={`/${ navitem.link.cached_url }`}>
                                                {navitem.name}
                                            </Link>
                                        </li>
                                    </SbEditable>
                                ))}
                                </ul>
                            </nav>
                        </div>
                        <div className="column-2">
                            <h4>{this.settings.footer_navi_title_2}</h4>
                            <nav className="footer-menu" role="navigation" aria-label={ this.t("footer.navigation.menu2.label") }>
                                <ul>
                                {this.settings && this.settings.content.footer_navi_2.map((navitem, index) => (
                                    <SbEditable content={navitem} key={navitem._uid}>
                                        <li key={index}>
                                            <Link to={`/${ navitem.link.cached_url }`}>
                                                {navitem.name}
                                            </Link>
                                        </li>
                                    </SbEditable>
                                ))}
                                </ul>
                            </nav>
                        </div>
                        <div className="column-3">
                            <nav className="footer-menu social-media" role="navigation" aria-label={ this.t("footer.navigation.menu3.label") }>
                                <ul>
                                    <li>
                                        <a href={this.settings.linkedin_link} className="linkedin-link" title={this.t("footer.navigation.socialMedia.linkedin.title")} target="_blank" rel="noreferrer">{this.t("footer.navigation.socialMedia.linkedin.label")}</a>
                                    </li>
                                    <li>
                                        <a href={this.settings.twitter_link} className="twitter-link" title={this.t("footer.navigation.socialMedia.twitter.title")} target="_blank" rel="noreferrer">{this.t("footer.navigation.socialMedia.twitter.label")}</a>
                                    </li>
                                    <li>
                                        <a href={this.settings.instagram_link} className="instagram-link" title={this.t("footer.navigation.socialMedia.instagram.title")} target="_blank" rel="noreferrer">{this.t("footer.navigation.socialMedia.instagram.label")}</a>
                                    </li>
                                    <li>
                                        <a href={this.settings.facebook_link} className="facebook-link" title={this.t("footer.navigation.socialMedia.facebook.title")} target="_blank" rel="noreferrer">{this.t("footer.navigation.socialMedia.facebook.label")}</a>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                    <div className="copyright">
                        &copy; {today.format('YYYY')} {this.t("footer.copyright")}
                    </div>
                </div>
            </footer>
          )
    }
}

export default withTranslation()(Footer)