import React, { Component }  from "react"
import TopStory from "../TopStory"
import LanguageSwitcher from "../LanguageSwitcher"
import Logo from "../Logo"
import Navigation from "../Navigation/index"

import "./styles.scss"

import { withTranslation } from "react-i18next"

class Header extends Component {
    constructor(props) {
        super(props)
        this.lang = props.lang
        this.settings = props.settings
        this.location = props.location
        this.t = props.t
    }

    render() {
        return (
            <header>
                <div className="meta-header">
                    <div className="container">
                        <TopStory settings={this.settings} />
                        <LanguageSwitcher lang={this.lang} />
                    </div>
                </div>
                <div className="main-header">
                    <div className="container">
                        <Logo lang={this.lang} />
                        <Navigation settings={this.settings} location={this.location} />
                    </div>
                </div>        
            </header>
        )
    }
}

export default withTranslation()(Header)
