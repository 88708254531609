import React, { Component } from "react"
import SbEditable from "storyblok-react"
import Button from "../../Button"

import "./styles.scss"


class Hero extends Component {
    constructor(props) {
        super(props)
        this.blok = props.blok
    }

    render() {
        return (
            <SbEditable content={this.blok}>
                <div className="hero-block">
                    <div className="container">
                        <div className="text">
                            <h1>{this.blok.headline}</h1>
                            <p>{this.blok.teaser}</p>
                            <div className="buttons">
                                {this.blok.buttons.map((button, i) => {
                                    return (<Button key={i} type={button.type} to={button.url} label={button.label} title={button.label} />)
                                })}
                            </div>
                        </div>
                        {this.blok.image.filename !== "" &&
                        <div className="image">
                            <img loading="lazy" src={this.blok.image.filename} alt={this.blok.image.alt} />
                        </div>
                        }
                    </div>
                </div>
            </SbEditable>
        )
    }
}  
  
export default Hero